import loadable from "@loadable/component";
import { inject } from "mobx-react";

import { Remote } from "../../components/remote/Remote.component";
import type { AptivadaKeywordAppFragment } from "@ihr-radioedit/inferno-webapi";
import { isKeywordContestBlock } from "../../lib/guards";
import { ILog } from "@ihr-radioedit/inferno-core";
import { getKeywordContest } from "../../services/Aptivada";
import { PageBlockInterface } from "../Block.component";
import type { Store } from "@inferno/renderer-shared-core";

const AptivadaKeywordContest = loadable(() => import("./AptivadaKeywordContest.component"));

const log = ILog.logger("ContestsTileLoader");

interface KeyContestsLoaderProps extends PageBlockInterface {
  store?: Store;
}

export const KeywordContestLoader = inject("store")(({ block, store }: KeyContestsLoaderProps) => {
  if (!store || store.site.sections.partners?.aptivada_switch !== true) {
    log.debug("Aptivada is disabled on the partners tab for this site");
    return null;
  }

  if (!isKeywordContestBlock(block)) {
    return null;
  }

  const appId = parseInt(block.campaignId || "0", 10);

  if (!appId) {
    return null;
  }

  const { site } = store;
  const { slug: accountId } = site.index;
  const cacheKey = `keyword-contest-loader-${accountId}-${appId.toString()}`;

  const loader = () =>
    getKeywordContest(
      {
        accountId: store?.site.index.slug || "",
        appId: appId.toString(),
      },
      store.tags.surrogateKeys || new Set(),
    );

  return (
    <Remote<AptivadaKeywordAppFragment | null | undefined> cacheKey={cacheKey} loader={loader} showLoading={false}>
      {({ data }) => (data ? <AptivadaKeywordContest keyword={data} /> : null)}
    </Remote>
  );
});

export default KeywordContestLoader;
